@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/src/css/main";
@import "../../node_modules/aos/dist/aos";
@import "../../node_modules/slick-carousel/slick/slick.scss";
// @import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $white-o;
    color: $black;
    font-family: $primary-font;
    letter-spacing: .02em;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1600);
}

.page-wrap{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    #header, #main, #footer{
        width: 100%;
    }
    #main{
        flex: 1;
    }
}

.socials{
    margin: 0;
    padding: rem(3) 0 0;
    color: $pink;
    li{
        display: inline-block;
        &+li{
            margin-left: .5em;
        }
    }
}

.btn, button{
    display: inline-block;
    border: 2px solid $white-o;
    color: $white-o;
    border-radius: 0;
    background: transparent;
    text-decoration: none;
    padding: rem(6) rem(10) rem(4);
    font-size: rem(13);
    text-transform: uppercase;
    transition: .3s all;
    line-height: 1;
    @include hover{
        color: $pink;
        background-color: $white-o;
    }
    &.pink{
        border-color: $pink;
        color: $pink;
        @include hover{
            color: $white-o;
            background-color: $pink;
        }
    }
    &.black{
        border-color: $black;
        color: $black;
        @include hover{
            color: $white-o;
            background-color: $black;
        }
    }
}

#header{
    position: fixed;
    z-index: 100;
    background-color: $white-o;
    top: 0;
    left: 0;
    width: 100%;
}

.menu-bar, .mobile-menu{
    nav{
        padding-top: rem(3);
        font-size: rem(13);
        text-transform: uppercase;
        color: $pink;
        a{
            text-decoration: none;
            span{
                display: inline-block;
                vertical-align:middle;
            }
        }
    }

    .newsletter{
        input{
            border: none;
            background:transparent;
            border-radius: 0;
            border-bottom: 1px solid $pink;
            color: $pink;
            font-size: rem(9);
            text-transform: uppercase;
            padding: rem(3) rem(5);
            &::placeholder{
                color: $pink;
                text-align: center;
            }
        }
        .btn{
            font-size: rem(9);
            padding: rem(3) rem(5) rem(1);
            border: 1px solid $pink;
        }
    }
}

.mobile-menu{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s;
    background-color: $white-o;
    z-index: 10;
    border-bottom: 1px solid $pink-l;
    
    @media screen and (min-width:1061px){
        display: none;
    }
    &.open{
        display: block;
        pointer-events: all;
        opacity: 1;
    }
    padding: rem(30) rem(10) rem(50);
    text-align: center;
    .socials{
        margin-bottom: rem(30);
    }
    nav{
        a{
            font-size: rem(20);
            display: block;
            width: max-content;
            margin: rem(30) auto; 
        }
    }
}

.menu-bar{
    display: flex;
    border: 1px solid $pink-l;
    >div{
        border-left: 1px solid $pink-l;
        padding: rem(3) rem(20);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h1{
        margin: 0;
        img{
            display: block;
        }
    }
    .grow{
        flex: 1;
    }

    nav{
        width: 100%;
        display: flex;
        justify-content: space-around;
        font-size: rem(13);
        text-transform: uppercase;
        color: $pink;
        a{
            &::before{
                content: '';
                vertical-align:middle;
                display: inline-block;
                width: rem(6);
                height: rem(6);
                border-radius: 50%;
                margin: 0 rem(2) rem(2) 0;
                border: 1px solid $pink;
            }
            @include hover{
                color: $pink;
                &::before{
                    background: $pink;
                }
            }
        }
    }
    .copyright{
        text-align: left;
    }
}

#header .menu-bar{
    >div:last-child{
        display: none;
        flex: 1;
    }
}

@media screen and (max-width:1060px){
    #header .menu-bar{
        >div{
            display: none;
        }
        >div:last-child,>div:first-child{
            display: flex;
            nav{
            justify-content: flex-end;
            }
        }
    }

    #footer .menu-bar{
        flex-direction: column;
        >div{
            border-left: none;
            border-top: 1px solid $pink-l;
            &:nth-child(2){
                order: 4;
                .copyright{
                    color: $pink;
                    font-size: rem(8);
                    text-align: center;
                }
            }
        }
    }
}

@keyframes spinning{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

#hero{
    background-color: $pink;
    margin-top: rem(22);
    .graphic{
        display: block;
        position: relative;
        >picture{
            width: 100%;
            height: auto;
            max-height: 100vh;
            display: block;
            img{
                width: 100%;
            }
        }
        // video logo
        //.logo{
        //     position: absolute;
        //     top: 50%;
        //     right: 0;
        //     height: 150%;
        //     transform: translateY(-50%);
        //     pointer-events: none;
        // }

        //image logo{
        .logo{
            position: absolute;
            top: 50%;
            right: rem(40);
            height: 80%;
            transform: translateY(-50%);
            pointer-events: none;
        }

        @media screen and (max-width: 1060px){
            >img:first-child{
                min-height: rem(500);
                object-position: 15% 50%;
            }

        
            .logo{
                height: auto;
                //video logo{
                // top: 100%;
                // width: 100%;
                //image logo
                top: calc(100% - 4vw);
                width: 70%;
                right: 15%;
            }
        }
    }
    
    .presaves{
        padding: 0 rem(10) rem(2);
        color: $white;
        max-width: rem(1400);
        display: flex;
        flex-wrap: wrap;
        .content{
            flex: 1;
            padding: rem(20) rem(40) rem(15);
            img{
                width: 100%;
                max-width: rem(550);
            }
            p{
                margin: rem(5) 0;
                font-size: rem(15);
                text-transform: uppercase;
            }
            .note{
                font-size: rem(8);
                flex-basis: 100%;
                width: 100%;
                max-width: rem(500);
                text-align: left;
                text-transform: uppercase;
                margin: 1em 0;
                
                @media screen and (max-width: 1060px){
                    text-align: center;
                    max-width: rem(300);
                    margin: 1em auto;
                }
            }
        }
        
        .wrap{
            position: relative;
            width: rem(350);
            max-width: 100%;
            padding-top: rem(15);
            @media screen and (max-width: 1060px){
                width: rem(250);
                padding: 0;
            }
            .btn.dropdown-btn{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .dropdown{ 
                position: absolute;
                width: 100%;
                &.closed:not(:focus-within){
                    pointer-events: none;
                    opacity: 0;
                }
                .btn{
                    background-color: $pink;
                    width: 100%;
                    margin: 0;
                    @include hover{
                        background-color: $white;
                    }
                }
                
            }
            .btn{
                display: block;
                text-align: center;
                padding: rem(4) rem(15) rem(2);
                // padding: rem(20) rem(15);
                line-height: 1.8;
                margin: rem(10) 0;
            }
        }

        @media screen and (max-width: 1060px){
            // padding-top: 20%;
            flex-direction: column;
            align-items: center;
            .ring{
                display: none;
            }
            .content{
                text-align: center;
                padding: rem(15) 0 0;
                margin: 0;
            }
        }
    }

    .ring{
        filter: brightness(0) invert(1);
        animation: spinning 10s infinite linear;
        margin-top: rem(-80);
        width: rem(200);
        height: rem(200);
    }
}

#tour{
    background-image: url('../img/tour-bg.jpg');
    background-size: cover;
    background-position: center left;
    color: $white-o;
    padding: rem(100) rem(30) rem(20);
    overflow: hidden;
    position: relative;
    // video{
    //     display: block;
    //     width: 100%;
    //     height: auto;
    //     min-height: rem(300);
    //     object-fit: cover;
    //     object-position: top left;
    // }
    .container{
        position: relative;
        z-index: 4;
        background-image: linear-gradient(90deg, #95468c, #2462a7);
        margin: 0 auto;
        padding: 0 rem(20) rem(20);
        >img{
            display: block;
            margin: 0 auto rem(-70);
            position: relative;
            top: rem(-90);
        }
    }
    @media screen and (min-width: 1061px){
        background-position: 80% 0;
        padding: rem(100) 0 rem(20);
        .container{
            max-width: rem(700);
        }
    }
    #events{
		margin: 0;
		&:not(.seeall){
			.event:nth-child(n+6){
				display: none;
			}
			.seealllink:nth-child(-n+4){
				display: none;
			}
		}
        &.seeall{
            .seealllink{
				display: none;
			}
        }
        .seealllink{
            width: 100%;
            margin: rem(10) auto 0;
            text-align: center;
        }
		.event{
			margin-bottom: rem(10);
			font-size: rem(13);
            text-transform: uppercase;
			text-align: left;
			display: flex;
			align-items: center;
            .btn{
                padding: rem(4) rem(10) rem(2);
            }
            .btn+.btn{
                margin-left: rem(10);
            }
			@media screen and (max-width: 1060px){
				flex-direction: column;
				align-items: center;
				margin-bottom: rem(20);
				>div{
					text-align: center;
					margin-bottom: .8em;
				}
			}
			.event-date{
				flex: .75;
			}
			.event-venue{
				flex: 2;
			}
			.event-location{
                padding-right: rem(10);
				flex: 1;
			}
			.event-links{
				flex: 1.2; 
			}
		}
	}
}

#music{
    padding: rem(60) 0;
    background-color: $blue;
    color: $white-o;
    >div{
        width: 100%;
    }
    .container{
        padding: 0;
    }
    @media screen and (min-width: 1061px){
        padding-right: 12%;
        .slick-next{
            right: -7%;
        }
        &.active{

            padding-left: 12%;
            .slick-prev{
                left: -7%;
                display: inline !important;
                width: rem(60);
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    @media screen and (max-width: 1060px){
        .slick-next{
            display: none !important;
        }
    }
    .item{
        width: rem(300);
        padding: 0 rem(10);
        p{
            font-size: rem(14);
            text-transform: uppercase;
            margin: 0;
            text-align: center;
        }
        img{
            display: block;
            margin: rem(10) auto;
            width: rem(240);
            max-width: 100%;
        }
    
        .btn{
            display: block;
            text-align: center;
            width: rem(240);
            max-width: 100%;
            margin: 0 auto
        }
    }
    
}

#video{
    background-color: $pink;
    
    >div{
        width: 100%;
    }
    @media screen and (min-width: 1061px){
        &:not(.active){
            padding-right: 20%;
            .slick-next{
                right: -15%;
            }
        }
    }

    @media screen and (max-width: 1060px){
        .slick-next{
            display: none !important;
        }
    }
    
    .item{
        position: relative;
    }
    video{
        width: 100%;
        height: auto;
        display: block;
    }
    p{
        font-size: rem(17);
        text-transform: uppercase;
        position: absolute;
        z-index: 2;
        top: 1em;
        width: 100%;
        margin: 0;
        text-align: center;
        &.white{
            color: $white-o;
        }
    }

    .btn{
        position: absolute;
        z-index: 2;
        bottom: rem(15);
        left: rem(15);
        right: rem(15);
        text-align: center;
    }

    &.active{
        padding-top: rem(40);
        p{
            font-size: rem(13);
        }
    }

    .video-carousel .slick-slide>div {
        margin-bottom: -5px;
    }
}

#music, #video, #video-inner{
    .slick-prev{
        display: none !important;
    }
    .slick-next{
        width: rem(60);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

#bio{
    display: none;
    background: $pink;
    color: $white;
    padding: rem(30) 0;
    h2{
        font-size: rem(40);
        text-transform: uppercase;
        margin: 0 0 1em;
    }
    .container{
        display: flex;
        align-items: stretch;
        
        >div{
            position: relative;
            flex: 1;
            &:last-child{
                padding: 0 rem(30);
            }
            p{
                font-size: rem(15);
                line-height: 1.3;
            }
            img{
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @media screen and (max-width: 1060px){
            display: block;
            >div{
                width: 100%;
                max-width: rem(600);
                margin: 0 auto;
                img{
                    position: static;
                    height: auto;
                    min-height: rem(400);
                }
                &.content{
                    padding: rem(20) 0;
                }
            }
        }
    }
}

#cta{
    padding: rem(30);
    a{
        display: block;
        background-image: linear-gradient(90deg, #516983, #b8c4d6, #516983);
        background-size: 200% auto;
        background-position: center left;
        color: $white;
        text-decoration: none;
        font-size: rem(26);
        text-transform: uppercase;
        font-weight: rem(300);
        text-align: center;
        padding: rem(30) rem(10) rem(25);
        border-radius: rem(40);
        @include hover{
            background-position: center right;
        }
    }
    @media screen and (max-width: 1060px) {
        padding: rem(25) rem(16);
        a{
            font-size: rem(22);
        }
    }
}

#main{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    section.active{
        flex-grow: 1;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

.copyright{
    font-size: rem(10);
    text-transform: uppercase;
}

.popup{
	background-color: $pink;
    color: $white;
	padding: rem(60) rem(15);
	border-radius: 0;
	margin: rem(30) auto;
	position: relative;
	width: rem(700);
	max-width: 100%;
	text-align: center;
    .btn{
        width: rem(350);
        max-width: 100%;
        margin: rem(15) auto;
    }
	input, select{
        display: block;
        border: none;
		border-bottom: 1px solid $white-o;
		border-radius: 0;
		color: $white-o;
        background-color: transparent;
		font-size: 12px;
		font-weight: 700;
		line-height: 1;
		padding: 7px 10px 6px;
		text-align: center;
		text-transform: uppercase;
        width: rem(360);
        max-width: 100%;
        margin: rem(15) auto;
		&::placeholder{
			color: $white-o;
		}
	}
    .mfp-close{
        border: 2px solid $white-o;
        color: $white-o;
        width: auto;
        height: auto;
        padding: 0 rem(3);
        top: rem(10);
        right: rem(10);
        line-height: 1;
        @include hover{
            color: $pink;
            background-color: $white-o;
        }
    }

    .inputs-wrap-signup{
        input{
            display: block;
            border: none;
            border-bottom: 1px solid $white-o;
            border-radius: 0;
            color: $white-o;
            background-color: transparent;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
            padding: 7px 10px 6px;
            text-align: center;
            text-transform: uppercase;
            width: rem(400);
            max-width: 100%;
            margin: rem(15) auto;
            &::placeholder{
                color: $white-o;
            }
        }
        .btn{
            width: rem(200);
        }

        p{
            color: $white;
            text-align: center;
            margin: 1em auto;
            font-size: rem(15);
            text-transform: uppercase;
            &:first-child{
                font-size: rem(13);
                font-family: 'NeueHelvetica65Bold', sans-serif;
            }
            &:nth-child(2){
                font-size: rem(13);
            }
            span{
                font-weight: bold;
                font-size: rem(16);
                display: block;
                font-family: 'NeueHelvetica65Bold', sans-serif;
            }
            &.newsletter-thanks{
                font-weight: bold;
                font-family: 'NeueHelvetica65Bold', sans-serif;
                margin: rem(100) 0;
            }
        }
        a{
            text-transform: uppercase;
            font-size: rem(8);
        }
        .note{
            font-size: rem(8);
            flex-basis: 100%;
            width: 100%;
            max-width: rem(300);
            text-transform: uppercase;
            margin: 1em auto;
            
            @media screen and (max-width: 1060px){
                text-align: center;
                max-width: rem(300);
                margin: 1em auto;
            }
        }
    }
}

@media screen and (max-width: 1060px){
    .desktop{
        display: none !important;
    }
}

@media screen and (min-width: 1061px){
    .mobile{
        display: none !important;
    }
}