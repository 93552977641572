//settings
$fa-font-path: "../font";

// Colors //
$black: #454545;
$white: #fff;
$white-o: #FDFBF5;
$pink: #C7AED2;
$blue: #B4C1D8;
$pink-l: #FFD6EC;
$primary-color: $pink-l;
$gray: #656565;

// $black: #454545;
// $white: #fff;
// $white-o: #FDFBF5;
// $pink: #C7AED2;
// // $pink: #FC91CA;
// $pink-l: #FFD6EC;
// $primary-color: $pink-l;
// $gray: #656565; 

//Fonts
$primary-font: 'NeueHelvetica65Medium', sans-serif;
